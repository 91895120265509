.rodape {
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: #45b97c;
    border-top: 5px solid #346399;

}

.rodapeLogin {
    /* position: fixed; */
    width: 100%;
    padding-top: 15px;
    padding-bottom: 2px;
    background-color: #45b97c;
    border-top: 5px solid #346399;
  }

.menu-container {
    display: flex;
    justify-content: flex-start;
    margin: 0 auto;
    max-width: 800px;
    
}

.menu-container ul {
    list-style-type: none;
    padding: 0;
    margin: 10px 20px 10px 10px;
    margin-left: 60px;
    width: 3000px; 
}

.menu-container ul li {
    margin-bottom: 1px;
    white-space: nowrap;
    
}

.menu-container ul li a {
    color: white;
    padding-top: 15px;
    padding-bottom: 15px;
    text-decoration: none;
    font-size: 12px;
    

}