header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;

    /* background-color: #515151; */
    /* gap: 32px; */
}

.topo-site ul {
    list-style-type: none;
    padding: 0;
    /* margin-left: 40px; */
    background-color: #515151;
    width: 100%;
}
.topo-site{
    width: 100%;
}

.topo-site ul li {
    display: inline-block;
    margin-right: 10px;
    color: rgb(207, 175, 175);
    transition: color 0.3s;
    margin-left: 20px
}

.topo-site ul li a {
    color: #ccc;
    line-height: 30px;
    margin-right: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 12px;
}

.topo-site ul li :hover {
    color: rgb(255, 255, 255);
}

.topo-site-title {
    background-color: rgb(255, 255, 255);
    width: 100%;
    height: 120px;
}

.topo-site-title h1 {
    font-family: "Titillium Web", sans-serif;
    font-size: 34px;
    margin-top: 16px;
    margin-bottom: 0;
    color: #515151;
    text-transform: uppercase;
    /* margin: 50px 12%; */
    margin-left: 12%;
    margin-top: 50px;

}

.topo-site-title p {
    /* background-color: red; */
    margin-left: 12%;
    /* margin: 50px 12%; */
}

.topo-site-logo {
    background-color: #f5f5f5;
    width: 100%;
    height: 120px;
}

.topo-site-logo img {
    background-color: #f5f5f5;
    /* width: 100%; */
    height: 120px;
    margin-left: 12%;
    /* margin-top: 4px; */
}

.menu-itens ul {
    display: flex;
    list-style: none;
    padding: 0;
    height: 35px;
    background-color: #4079bc;
}

.menu-itens ul li {
    
    display: block;
    margin-bottom: 10px; /* Espaçamento entre cada item de menu */
}

.menu-itens ul li a {
    display: block;
    padding: 8px 15px; /* Espaçamento dentro de cada item de menu */
    text-decoration: none;
    color: #000; /* Cor do texto */
}

.menu-itens {
    width: 100%;
    height: 50px;
}

.menu-itens ul li a {
    color: #fff;
    font-size: 14px;
}


.icon-logout {
    float: right;
    /* background-color: red; */
}