.dropzone {
  height: 300px;
  background: #76B5E5;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -5px;
  outline: 0;
}

.button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  color: #fff;
  /* Cor do texto */
  border-top: 50px;
}

/* Estilo de fundo azul para o botão */
.button-blue {
  background-color: #007bff;
  margin-top: 10px;
  /* Cor azul */
}
/* Estilo hover para o botão */
.button:hover {
  
  background-color: #0056b3;
  /* Cor mais escura ao passar o mouse */
}

.button-container {
  /* margin-top: 2% */
    /* Ajuste a quantidade de espaçamento superior conforme necessário */
}


.center-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.centered-div {

  text-align: center;
  padding: 5px;
  /* margin-top: 10%; */
  /* Ajuste a quantidade de espaçamento superior conforme necessário */
}

.img-img {

  width: 100px;
  /* Ajuste conforme necessário */
  height: auto;
  margin-bottom: 2%;
}