
/* .teste1{
    transform: translate(30%, 0%);
}      */

.how-to ol li {
    display: flex;
    align-items: center;
    /* padding: 2px 0; */
    font-size: 18px;
    line-height: 10px;
    border-bottom: 1px solid #D4D4D4;
    /* transform: translateY(-50%); */
}

.how-to ol li h1 {
    font-size: 25px;
    line-height: 10px;
}


.title-how-to {
    padding: 2px 5px;
    font-size: 20px;
    color: #2c2c2c;
    border-bottom: 1px solid #D4D4D4;
    margin-bottom: 3px;
}

.how-to li div {
    margin-left: 10px;
}